















import { ErrorResponse } from '@apollo/client/link/error';
import { defineComponent } from '@vue/composition-api';
import { addDays } from 'date-fns';
import { mapStores } from 'pinia';
import SbEnvironmentBorder from '@/components/SbEnvironmentBorder.vue';
import SbErrorBoundary from '@/components/SbErrorBoundary.vue';
import Symbols from '@/components/SvgSymbols.vue';
import MutationAuthenticateTeamleader from '@/graphql/mutations/AuthenticateTeamleader.gql';
import { Role } from '@/graphql/types';
import { QueueLink } from '@/lib/apollo/queue';
import { date } from '@/lib/filters/date';
import { logger } from '@/logger';
import { useAuthStore } from '@/stores/auth';
import { setGraphqlErrorHandler } from './lib/apollo/error';
import { isAdminContext } from './lib/helpers';
import { getRole, handleGraphQLErrors } from './lib/util-helper';

export default defineComponent({
  name: 'App',
  components: {
    SbErrorBoundary,
    SbEnvironmentBorder,
    Symbols,
  },
  data() {
    return {
      activeRow: null,
      version: process.env.VUE_APP_VERSION,
    };
  },
  computed: {
    ...mapStores(useAuthStore),
    role: () => getRole(),
    baseUrl(): string {
      const url = window.location.href;
      return `${url.split('/')[0]}//${url.split('/')[2]}`;
    },
    pauseGql(): boolean {
      return this.authStore.state !== 'authenticated';
    },
  },
  watch: {
    pauseGql: {
      handler(value: boolean) {
        switch (value) {
          case true:
            QueueLink.close();
            break;
          case false:
          default:
            QueueLink.open();
            break;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    logger.info('Typetuin Beheer version', this.version);

    setGraphqlErrorHandler(this.handleGraphqlError);

    this.checkTeamleaderAuthentication();
    // extends router to have a navigateback function
    (this.$router as TODO).navigateBack = () => {
      if (window.history?.length > 2) {
        this.$router.go(-1);
      } else {
        this.$router.push('/');
      }
    };
  },
  methods: {
    async checkTeamleaderAuthentication() {
      if (this.authStore.role !== Role.Admin) return;

      function getUrlParameter(name: TODO) {
        // eslint-disable-next-line
        const parsedName = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        const regex = new RegExp(`[\\?&]${parsedName}=([^&#]*)`);
        const results = regex.exec(window.location.search);
        return results === null
          ? ''
          : decodeURIComponent(results[1].replace(/\+/g, ' '));
      }

      if (getUrlParameter('error')) {
        this.$Modal.error({
          title: 'Fout',
          content: `Er is iets fout gegaan bij de teamleader authencitation: ${getUrlParameter(
            'error',
          )}`,
        });
        return;
      }

      if (getUrlParameter('code')) {
        logger.debug('TEAMLEADER CODE', getUrlParameter('code'));
        await this.$apollo.mutate({
          mutation: MutationAuthenticateTeamleader,
          variables: {
            data: {
              code: getUrlParameter('code'),
              redirectUri: this.baseUrl,
            },
          },
        });
        this.$Modal.success({
          title: 'Gelukt',
          content: 'Teamleader is succesvol gekoppeld',
          onOk: () => {
            window.location.href = this.baseUrl;
          },
        });
      }
    },
    environment() {
      const { resourceId, resourceType } = this.$route.params;

      if (this.$route.meta && this.$route.meta.allowUnauthorized) return '';
      if (isAdminContext(this.$router)) return 'default';
      if (this.authStore.role === Role.Coach) return 'coaches';
      if (resourceType === 'partner') return 'partners';
      if (resourceType === 'school_district') return 'schooldistricts';
      if (resourceId !== 'klassikaal' && resourceId !== 'online')
        return 'schools';

      return this.$route.params.resourceId;
    },
    handleGraphqlError(error: ErrorResponse): void {
      logger.getLogger('GraphQL').error(error);

      if (error.graphQLErrors) {
        const handled = error.graphQLErrors
          .map((graphQLError) => {
            switch (graphQLError.message) {
              case 'Refresh token is missing':
              case 'Invalid Refresh Token':
              case 'Invalid CSRF-token':
                this.authStore
                  .logout('cookie')
                  .then(() => this.$router.replace({ name: 'Login' }));
                return true;
              case 'Missing CSRF-token (hash)':
                logger
                  .getLogger('GraphQL')
                  .error(
                    `Skipped handling 'Missing CSRF-Token (hash)'`,
                    graphQLError,
                  );
                return true;
              default:
                logger
                  .getLogger('GraphQL')
                  .error('Unhandled GraphQLError', graphQLError);
                return false;
            }
          })
          .some((value) => value === true);

        if (handled) {
          return;
        }

        logger.error(error);
        const status =
          error.graphQLErrors[0].extensions &&
          error.graphQLErrors[0].extensions.exception
            ? error.graphQLErrors[0].extensions.exception.status
            : null;
        const { query, code, exception } = error.graphQLErrors[0].extensions;
        const { message } = error.graphQLErrors[0];
        const errorCode =
          exception.response && exception.response.error
            ? exception.response.error
            : code;

        logger.debug('error message:', message, 'error status', status);

        if (code === 'TEMP_PASSWORD' && status === 206) {
          return this.$Modal.info({
            title: 'Tijdelijk wachtwoord',
            content:
              'Je hebt een tijdelijk wachtwoord. Je wordt naar een pagina geleid om dit naar een wachtwoord naar keuze te veranderen.',
            onOk: () => {
              this.$router.push({
                name: 'NewPassword',
                query: {
                  token: exception.response.token,
                  user_id: exception.response.userId,
                },
              });
            },
          });
        }

        const errorMap: TODO = {
          START_DATE_IN_FUTURE:
            'De startdatum van de cursus ligt in de toekomst. Probeer het later nog eens.',
          END_DATE_IN_PAST:
            'De eindatum van je cursus is verstreken. Je kunt niet meer inloggen.',
          NO_LICENSE:
            'Er is op dit moment geen licentie gekoppeld aan je account. Neem contact op met de beheerder.',
          PASSWORD_NOT_SET:
            'Voltooi eerst de stappen in de registratie e-mail die je ontvangen hebt, of vraag een beheerder je wachtwoord in te stellen.',
          INVALID_CREDENTIALS:
            'Je gebruikersnaam en / of wachtwoord kloppen niet',
          SCHOOL_DEACTIVATED: 'De school is niet meer actief',
          RESOURCE_DEACTIVATED:
            'Deze entiteit (school / schooldistrict / partner) is niet meer actief',
        };

        const couponErrorMap: TODO = {
          COUPON_MAX_USAGE_EXCEEDED:
            'De coupon is een maximaal aantal keer gebruikt',
          UNKNOWN_COUPON_CODE: 'Ongeldige coupon code',
          COUPON_EXPIRED: 'De coupon is verlopen',
        };

        if (code === 'INVALID_COUPON') {
          return this.showErrorModal(
            couponErrorMap[exception.code]
              ? couponErrorMap[exception.code]
              : message,
          );
        }

        if (errorMap[errorCode]) {
          return this.showErrorModal(errorMap[errorCode]);
        }
        if (errorMap[code]) {
          return this.showErrorModal(errorMap[code]);
        }

        if (status === 400 && message === 'Invalid Refresh Token') {
          this.authStore
            .logout('cookie')
            .then(() => this.$router.replace({ name: 'Login' }));
          return;
        }

        if (status === 400) {
          if (message === "School can't migrate, licenses would be invalid") {
            return this.showErrorModal(
              'We kunnen de school niet migreren. Er zou een conflict ontstaan met de licenties. Een school met een eigen abonnement, kan niet onder een schooldistrict vallen dat ook een abonnement heeft.',
            );
          }
          if (
            message === "Subscription can't be cancelled within the last month"
          ) {
            return this.showErrorModal(
              `Je kunt het nieuwe abonnement opzeggen vanaf ${date(
                addDays(
                  new Date(exception.response.details.currentRenewalDate),
                  1,
                ),
              )}.`,
            );
          }
          if (message === 'School with BRIN/VAT-number already exists.') {
            return this.showErrorModal(
              'Deze organisatie heeft al een account in ons systeem. Log eerst in met je accountgegevens om licenties bij te bestellen. Heb je vragen over je account, of ben je je accountgegevens kwijt, neem dan contact op via support@brightskills.nl of bel 013-2047022. ',
            );
          }
          logger.debug('400 error', handleGraphQLErrors(error));
          return this.showErrorModal(handleGraphQLErrors(error));
        }
        if (status === 401) {
          this.authStore
            .logout('cookie')
            .then(() => this.$router.replace({ name: 'Login' }));
          return;
        }

        if (status === 403) {
          if (message === 'Not allowed to get students') {
            this.$Message.error('Je hebt geen toegang tot deze studenten');
            return;
          }
        }

        if (status === 404) {
          if (query.includes('{me{')) {
            logger.debug(
              'User not found. This can happen when you have a valid token, but the user is not in current database',
            );
            this.authStore
              .logout('cookie')
              .then(() => this.$router.replace({ name: 'Login' }));
            return;
          }
          logger.debug({
            message,
          });
        }
        return this.showErrorModal(message);
      }

      if (error.networkError) {
        logger.error('NETWORK ERROR', error.networkError);
        if (
          error.networkError.message.includes(
            'Unexpected token < in JSON at position 0',
          )
        ) {
          this.showErrorModal(
            'Het lijkt erop dat er problemen zijn met de server. Probeer het later nog eens',
          );
          this.$router.push({
            name: 'Login',
          });
          return;
        }
        const { status } = (error.networkError as TODO).statusCode;
        logger.debug(status);
        if (RegExp('5[0-9][0-9]').test(status)) {
          this.showErrorModal(
            'Het lijkt erop dat er problemen zijn met de server. Probeer het later nog eens',
          );
        }
        this.showErrorModal(
          'Het lijkt erop dat er problemen zijn met de server of het netwerk. Probeer het later nog eens',
        );
      }

      return;
    },
    showErrorModal(content: string) {
      this.$Modal.error({
        title: 'Foutmelding',
        content,
      });
    },
    handleBoundaryError(error: TODO) {
      logger.getLogger('Boundary').error(error);

      // Handle GraphQL errors seperately in graphqlErrorHandler
      if (
        error.err.message.includes('GraphQL error') ||
        error.err.message.includes('Network error') ||
        error.err.message ===
          'Store reset while query was in flight (not completed in link chain)'
      ) {
        return;
      }

      this.showErrorModal(error.err);
    },
  },
});
