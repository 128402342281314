import flatten from 'flat';
import resultScaleMapping from '@/data/resultScaleMapping.json';
import { logger } from '@/logger';
import { useAuthStore } from '@/stores/auth';

export function getRole() {
  return useAuthStore().role;
}

export function getResourceType() {
  return useAuthStore().resourceType;
}

export function getResource() {
  return useAuthStore().resourceId;
}

export function getSchool() {
  return useAuthStore().school;
}

export function getId() {
  return useAuthStore().jwt.sub;
}

export function handleGraphQLErrors(e) {
  logger.debug(e.graphQLErrors);
  return e.graphQLErrors
    .map((graphQLError) => {
      if (
        typeof graphQLError.extensions.exception.response.message === 'string'
      )
        return graphQLError.extensions.exception.response.message;
      return graphQLError.extensions.exception.response.message.join(', ');
    })
    .join(', ');
}

// flatten the data and remove graphql created keys with __typename
export function stripAndFlatten(data) {
  if (!data || !data.length) return null;
  const keysToDelete = Object.keys(flatten(data[0])).filter((key) =>
    key.includes('__typename'),
  );
  return data.map(flatten).map((item) => {
    keysToDelete.forEach((keyToDelete) => {
      // eslint-disable-next-line no-param-reassign
      delete item[keyToDelete];
    });

    // Object.keys(item).forEach((key) => {
    //   if (item[key] === null) {
    //     item[key] = '-';
    //   }
    // });

    return item;
  });
}

export const findAndReplaceObject = (input, needle, replacement) =>
  Object.fromEntries(
    Object.entries(input).map(([key, entry]) => {
      if (entry === needle) {
        return [key, replacement];
      }
      if (Object.prototype.toString.call(entry) === '[object Object]') {
        return [key, findAndReplaceObject(entry, needle, replacement)];
      }
      return [key, entry];
    }),
  );

export function defaultFiller(data, fillers) {
  return data.map((item) => {
    Object.keys(item).forEach((key) => {
      if (item[key] === null && fillers[key]) {
        // eslint-disable-next-line no-param-reassign
        item[key] = fillers[key];
      }
    });
    return item;
  });
}

export function calculateScaleMapping(value, type) {
  if (!resultScaleMapping[type] || value === null) return 'none';
  return (
    5 - resultScaleMapping[type].filter((result) => value >= result).length
  );
}

export function scaleMappingExists(type) {
  return resultScaleMapping[type];
}
