import { ApolloLink } from '@apollo/client/link/core';
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import {
  createApolloClient,
  restartWebsockets,
  // @ts-expect-error Not typed
} from 'vue-cli-plugin-apollo/graphql-client';
import { CsrfTokenLink } from '@/lib/apollo/csrf-token';
import { ErrorLink } from '@/lib/apollo/error';
import { QueueLink } from '@/lib/apollo/queue';
import { SentryRequestIdLink } from '@/lib/apollo/sentry-request-id';
import { FILES_ROOT, GRAPHQL_HTTP } from '@/lib/env';
import { logger } from '@/logger';

Vue.use(VueApollo);

// Http endpoint
const httpEndpoint = GRAPHQL_HTTP ?? '';
// Files URL root
export const filesRoot =
  FILES_ROOT || httpEndpoint.substring(0, httpEndpoint.indexOf('/graphql'));

Vue.prototype.$filesRoot = filesRoot;

// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  httpLinkOptions: {
    credentials: 'include',
  },
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  // wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:9002/graphql',
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: ApolloLink.from([
    ErrorLink,
    QueueLink,
    SentryRequestIdLink,
    CsrfTokenLink,
  ]),

  // Override default cache
  // cache: myCache

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...

  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
};

export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  });
  apolloClient.wsClient = wsClient;

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    clients: {
      apolloClient,
    },
    defaultClient: apolloClient,
  });

  return apolloProvider;
}

export const apollo = createProvider();

export async function resetApollo() {
  // @ts-expect-error Not typed correctly
  if (apollo?.defaultClient?.wsClient)
    // @ts-expect-error Not typed correctly
    restartWebsockets(apollo.defaultClient.wsClient);

  try {
    apollo.defaultClient.stop();
    await apollo.defaultClient.resetStore();
  } catch (error) {
    logger.getLogger('Apollo').error('Reset Store', error);
    throw error;
  }
}
