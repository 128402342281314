import { createPinia, PiniaVuePlugin } from 'pinia';
import Vue from 'vue';
import '@/composition-api';
import '@/lib/mixins';
import '@/components';
import '@/scroll-to';
import '@/iview';
import VueRouter from 'vue-router';
import { apollo } from '@/apollo';
import { i18n } from '@/i18n';
import { router } from '@/router';
import { setupSentry } from '@/sentry';
import App from './App.vue';

/** START -- Pinia */
Vue.use(PiniaVuePlugin);
export const pinia = createPinia();
/** END -- Pinia */

Vue.use(VueRouter);

setupSentry(router);

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  pinia,
  apolloProvider: apollo,
  render: (h) => h(App),
}).$mount('#app');
