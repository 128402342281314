import type { default as VueRouter } from 'vue-router';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import Vue from 'vue';
import {
  SENTRY_DSN,
  NODE_ENV,
  RELEASE_GIT_SHORT_SHA,
  SENTRY_LOG_LEVEL,
  SENTRY_TRACES_SAMPLE_RATE,
} from '@/lib/env';
import { getClientInfo } from '@/lib/get-client-info';
import { logger } from '@/logger';

export function setupSentry(router: VueRouter) {
  if (!SENTRY_DSN) return;

  Sentry.init({
    Vue,
    environment: NODE_ENV,
    release: RELEASE_GIT_SHORT_SHA,
    dsn: SENTRY_DSN,
    logErrors: SENTRY_LOG_LEVEL === '1',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
    ],
    // We recommend adjusting this value in production, or using tracesSampler for finer control
    tracesSampleRate: Number(SENTRY_TRACES_SAMPLE_RATE ?? '0'),
  });

  // fetch client info
  getClientInfo()
    .then((client) => {
      client.ip_address = client.ip;
      delete client.ip;
      Sentry.setUser(client);
    })
    .catch(logger.getLogger('Sentry').error);
}
