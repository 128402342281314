import VueRouter from 'vue-router';
import { Role } from '@/graphql/types';
import { useAuthStore } from '@/stores/auth';

export function isAdminContext(router: VueRouter): boolean {
  return (
    router.currentRoute.params.resourceId === 'default' &&
    useAuthStore().role === Role.Admin
  );
}

export function context(router: VueRouter): string {
  return router.currentRoute.params.resourceType.toUpperCase();
}
